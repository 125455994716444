'use strict'

const PropTypes = require('prop-types')
const React = require('react')
const RadioInput = require('../../inputs/radio')

const ActionList = require('@npm/design-system/action-list/action-list')
const TFAStatus = require('../../memberships/tfa-status')
const InputGeneric = require('../../inputs/generic')
const InputHidden = require('../../inputs/hidden')
const forms = require('../../../styles/forms.css')
const Checkbox = require('../../inputs/checkbox')
const Avatar = require('@npm/design-system/avatar/avatar')
const Form = require('../../forms/generic')
const types = require('../../../types')
const styles = require('./admin.css')
const GithubIcon = require('../../icons/github-svg')
const GitlabIcon = require('../../icons/gitlab-svg')
const SelectInput = require('../../inputs/select')

function inviteMaintainerForm(changeAction, formData) {
  return (
    <div>
      <h3 id="invite-maintainer-heading" className={styles.heading}>
        Invite maintainer
      </h3>

      <Form
        className="mh0 mt3 mb4"
        method="POST"
        action={changeAction}
        formData={formData}
        buttonText="Invite"
        formId={'invite'}
      >
        <InputGeneric
          name="add"
          label="Username"
          initialValue=""
          formData={formData}
          ariaLabelledBy="invite-maintainer-heading UsernameId"
        />
      </Form>
    </div>
  )
}

function Admin(props) {
  const {
    pkg,
    maintainers,
    invitations,
    private: isPrivate,
    formData = {},
    canBePrivate,
    canAddMaintainers,
    canRemoveMaintainers,
    isPackageInviteFFEnabled,
    changeAction,
    isDeletable,
    deprecated,
    oidcConnections,
  } = props

  const visibility = isPrivate ? 'private' : 'public'
  return (
    <div>
      <Oidc oidcConnections={oidcConnections} formData={formData} action={changeAction} packageName={pkg} />

      <h2 className={styles.heading}>Package access</h2>
      <dl>
        <dt className={styles.defTitle}>Status:</dt>
        <dd className={styles.defDataStatus}>{visibility}</dd>
      </dl>

      <PackageSettingsForm
        canBePrivate={canBePrivate}
        action={changeAction}
        formId="package-settings"
        formData={formData}
      />

      {canAddMaintainers && inviteMaintainerForm(changeAction, formData)}
      {canAddMaintainers && isPackageInviteFFEnabled && (
        <ActionList type="Invitation" total={invitations.length} perPage={1000}>
          {invitations.map(invite => {
            const {user, avatars} = invite
            return (
              <div className={styles.listItem} key={`maintainer-${user.name}`}>
                <div className="flex-none">
                  <Avatar
                    size="small"
                    role="img"
                    title=""
                    aria-label={`${user.name} profile picture`}
                    src={avatars ? avatars.medium : ''}
                  />
                </div>
                <p className={styles.invitation}>{user.name}</p>
                {revokeInvitationButton(formData, user.name, changeAction)}
              </div>
            )
          })}
        </ActionList>
      )}

      <ActionList type="Maintainer" total={maintainers.length} perPage={1000}>
        {maintainers.map(maintainer => {
          const {user, permissions, grantor} = maintainer
          const {name, tfa, avatars} = user
          return (
            <div className={styles.listItem} key={`maintainer-${name}`}>
              <div className="flex-none">
                <Avatar
                  size="small"
                  role="img"
                  title=""
                  aria-label={`${name} profile picture`}
                  src={avatars ? avatars.medium : ''}
                />
              </div>
              <div className={styles.listItemTextContainer}>
                <p className={styles.maintainer}>{name}</p>
                <TFAStatus tfa={tfa} />
                <p className={styles.permissions}>{permissions} access</p>
                {grantor && maintainershipGrantor(grantor)}
              </div>
              {!grantor && canRemoveMaintainers && removeMaintainerButton(changeAction, formData, name)}
            </div>
          )
        })}
      </ActionList>
      {!deprecated && (
        <React.Fragment>
          <h3 className={styles.heading}>Deprecate package</h3>
          <p>This will mark all versions of the package as deprecated.</p>
          <p>
            Please see npm&apos;s <a href="https://docs.npmjs.com/policies/unpublish">Unpublish Policy</a> for more
            info.
          </p>
          <a href={`/package/${encodeURIComponent(pkg)}/deprecate`} className={`${forms.buttonGradientRed} db`}>
            Deprecate package
          </a>
        </React.Fragment>
      )}

      {isDeletable && (
        <React.Fragment>
          <h3 className={styles.heading}>Delete package</h3>
          <p>Once you delete this package, you will lose access to it. Please be certain.</p>
          <a href={`/package/${encodeURIComponent(pkg)}/delete`} className={`${forms.buttonGradientRed} db`}>
            Delete package
          </a>
        </React.Fragment>
      )}
    </div>
  )
}

const publishers = [
  {
    name: 'GitHub',
    value: 'github-actions',
    Icon: GithubIcon,
  },
  {
    name: 'GitLab',
    value: 'gitlab',
    Icon: GitlabIcon,
  },
]

function OidcButtons({onClick}) {
  return (
    <React.Fragment>
      <p style={{fontWeight: 'bold'}}>Select your publisher</p>
      <div className="flex items-center" style={{gap: '10px'}}>
        {publishers.map(({name, value, Icon}) => (
          <button
            key={value}
            aria-label={`Add OIDC Connection for ${name}`}
            className={`${forms.buttonGradient}`}
            onClick={() => onClick(value)}
            style={{width: '105px', height: '36px'}}
            disabled={value === 'gitlab'}
          >
            <div className="flex items-center">
              <div>
                <Icon size={15.75} />
              </div>
              <span style={{marginLeft: '10px'}}>{name}</span>
            </div>
          </button>
        ))}
      </div>
    </React.Fragment>
  )
}

function OidcView({oidcConnections, formData, packageName, action}) {
  return (
    <React.Fragment>
      <ActionList perPage={1000}>
        {oidcConnections.map(connection => {
          const {publisher, repository_owner: repositoryOwner, repository_name: repositoryName} = connection
          const PublisherIcon = publishers.find(pub => pub.value === publisher)?.Icon || (() => <React.Fragment />)
          return (
            <div key={`${publisher}-${repositoryOwner}-${repositoryName}`} className={styles.listItem}>
              <div className="flex">
                <div>
                  <PublisherIcon size={24} />
                </div>
                <span style={{marginLeft: '10px'}}>{`${repositoryOwner}/${repositoryName}`}</span>
              </div>
              <RemoveOidcConnection {...{formData, packageName, changeAction: action}} />
            </div>
          )
        })}
      </ActionList>
    </React.Fragment>
  )
}

function OidcCreate({action, formData, defaultPublisher}) {
  return (
    <React.Fragment>
      <Form
        className="mh0 mt3 mb4"
        method="POST"
        action={action}
        formData={formData}
        buttonText="Set up connection"
        formId={'oidc'}
        buttonClassName={forms.buttonGradient + ' ' + forms.btnFitContent}
      >
        <div style={{margin: '-10px'}}>
          <SelectInput
            name="publisher"
            label="Publisher"
            value={defaultPublisher}
            values={publishers.map(({value, name, Icon}) => ({
              value,
              label: (
                <div className="flex items-center">
                  <div>
                    <Icon size={20} />
                  </div>
                  <span style={{marginLeft: '10px'}}>{name}</span>
                </div>
              ),
            }))}
            formData={formData}
            ariaLabelledBy="add-connection-publisher"
            className={styles.selectInput}
            isMandatory={true}
          />
        </div>
        <div className={styles.inlineInputsContainer}>
          <InputGeneric
            name="repositoryOwner"
            label="Organization or user"
            initialValue=""
            formData={formData}
            ariaLabelledBy="add-connection-owner"
            className={styles.inputOwner}
            textboxClassName={styles.input}
            isMandatory={true}
          />
          <span className={styles.inlineSeparator}>/</span>
          <InputGeneric
            name="repositoryName"
            label="Repository"
            initialValue=""
            formData={formData}
            ariaLabelledBy="add-connection-repository"
            className={styles.inputRepository}
            textboxClassName={styles.input}
            isMandatory={true}
          />
        </div>
        <div>
          <InputGeneric
            name="workflowName"
            label="Workflow name"
            initialValue=""
            formData={formData}
            ariaLabelledBy="add-connection-workflow-name"
            textboxClassName={styles.workflowNameInput}
            isMandatory={true}
          />
        </div>
        <div>
          <InputGeneric
            name="environmentName"
            label="Environment name"
            initialValue=""
            formData={formData}
            ariaLabelledBy="add-connection-environment-name"
            textboxClassName={styles.input}
            labelDetails={
              <span className={styles.labelDetails}>
                The name of the{' '}
                <a
                  href="https://docs.github.com/en/actions/managing-workflow-runs-and-deployments/managing-deployments/managing-environments-for-deployment"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  GitHub Actions environment
                </a>{' '}
                used for publishing. This is encouraged for projects with maintainers who should not have npm publishing
                access.
              </span>
            }
          />
        </div>
      </Form>
    </React.Fragment>
  )
}

function Oidc({oidcConnections, packageName, formData, action}) {
  // Check if the feature flag is disabled
  const isOidcFeatureFlagDisabled = oidcConnections === undefined
  const [publisher, setPublisher] = React.useState(undefined)

  if (isOidcFeatureFlagDisabled) {
    return <React.Fragment />
  }

  const status =
    Array.isArray(oidcConnections) && oidcConnections.length ? 'show-view' : publisher ? 'show-create' : 'show-buttons'

  return (
    <React.Fragment>
      <h2 className={styles.heading}>Trusted Publisher</h2>
      <p>
        Establish a trust between your package and your repository using OpenID Connect (OIDC).
        <br />
        <a href="https://gh.io/npm-docs-trusted-publishers" target="_blank" rel="noopener noreferrer">
          Learn more about OpenID Connect.
        </a>
      </p>
      {status === 'show-buttons' && <OidcButtons onClick={setPublisher} />}
      {status === 'show-view' && <OidcView {...{oidcConnections, formData, packageName, action}} />}
      {status === 'show-create' && <OidcCreate {...{action, formData, defaultPublisher: publisher}} />}
    </React.Fragment>
  )
}

function PackageSettingsForm({action, formId, formData, canBePrivate}) {
  const values = [
    {
      value: 'tfa-not-required',
      label: "Don't require two-factor authentication",
    },
    {
      value: 'tfa-required-unless-automation',
      label: 'Require two-factor authentication or an automation or granular access token',
    },
    {
      value: 'tfa-always-required',
      label: 'Require two-factor authentication and disallow tokens',
    },
  ]

  return (
    <Form
      action={action}
      formId={formId}
      formData={formData}
      method="POST"
      className="ma0-ns"
      buttonText="Update Package Settings"
      buttonClassName={forms.buttonGradient + ' ' + forms.btnFitContent}
    >
      {canBePrivate && <Checkbox name="private" formData={formData} label={'Is Package Private?'} />}
      <div id={`${formId}_publishingAccess_radiogroup_label`}>
        <h2 className={styles.heading}>Publishing access</h2>
        Requiring an additional authentication method adds another level of security for your package.
      </div>
      <RadioInput
        name="publishingAccess"
        formData={formData}
        values={values}
        label=""
        fieldsetClassName={forms.fieldsetBgWhite}
      />
    </Form>
  )
}

Admin.propTypes = {
  maintainers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      access: PropTypes.string,
    }),
  ).isRequired,
  invitations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  changeAction: PropTypes.string.isRequired,
  private: PropTypes.bool.isRequired,
  formData: types.formData,
  canBePrivate: PropTypes.bool,
  canAddMaintainers: PropTypes.bool,
  canRemoveMaintainers: PropTypes.bool,
  isPackageInviteFFEnabled: PropTypes.bool,
  isDeletable: PropTypes.bool,
  deprecated: PropTypes.bool,
  oidcConnections: PropTypes.array,
}

Admin.defaultProps = {
  formData: {},
  canBePrivate: false,
  canAddMaintainers: false,
  canRemoveMaintainers: false,
  oidcConnections: undefined,
}

function maintainershipGrantor(grantor) {
  let URL, description
  if (grantor && grantor.org) {
    if (grantor.team) {
      URL = `/settings/${encodeURIComponent(grantor.org)}/teams/team/${encodeURIComponent(grantor.team)}/users`
      description = `${grantor.team} team`
    } else {
      URL = `/settings/${encodeURIComponent(grantor.org)}/members`
      description = `${grantor.org} org`
    }
    return (
      <p className={styles.maintainershipGrant}>
        (via <a href={URL}>{description}</a>)
      </p>
    )
  }
  return null // Add return null to handle all code paths
}

function removeMaintainerButton(changeAction, formData, name) {
  // Remove unused parameter 'maintainers'
  return (
    <Form
      className={styles.deleteForm}
      method="POST"
      action={changeAction}
      formData={formData}
      formId={`delete-${name}`}
      buttonText="×"
      buttonClassName={`${forms.deleteButton}`}
      buttonAriaLabel={`Remove maintainer ${name} from this package`}
    >
      <InputHidden name="remove" value={name} formId={`delete-${name}`} formData={formData} />
    </Form>
  )
}

function revokeInvitationButton(formData, userName, changeAction) {
  return (
    <Form
      className={styles.revokeForm}
      method="POST"
      action={changeAction}
      formData={formData}
      formId={`revoke-${userName}`}
      buttonText="×"
      buttonAriaLabel={`Revoke invitation for ${userName}`}
      buttonClassName={`${forms.deleteButton}`}
    >
      <InputHidden name="revoke" value={userName} formId={`revoke-${userName}`} formData={formData} />
    </Form>
  )
}

function RemoveOidcConnection({formData, changeAction}) {
  return (
    <Form
      className={styles.revokeForm}
      method="POST"
      action={changeAction}
      formData={formData}
      formId={`deleteOidc`}
      buttonText="delete"
      buttonAriaLabel={`remove oidc`}
      buttonClassName={`${forms.buttonGradientRed}`}
    >
      <InputHidden name="deleteOidc" value={true} formId={`deleteOidc`} formData={formData} />
    </Form>
  )
}

module.exports = Admin
